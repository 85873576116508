<template src="./Vaccinations.html">
</template>
<script>
    export default {
        name: "Vaccinations",
        components: {
        },
        created() {
        },
        mounted() {
        },
        data: () => ({
        }),
        methods: {
        }
    }
</script>

<style lang="scss">
</style>